// PreviewModal.js
import React from "react";
import Modal from "react-modal";
import Preview from "./Preview";

const PreviewModal = ({ isOpen, handleClose, editorData }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <h2>Preview</h2>
      {/* <div dangerouslySetInnerHTML={{ __html: editorData }}></div> */}
      <Preview />
      <button className="btn btn-danger btn-lg my-3" onClick={handleClose}>
        Close
      </button>
    </Modal>
  );
};

export default PreviewModal;
