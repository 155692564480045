import {
    GET_SUB_CATEGORY,
  } from "../Action/actionType";
  const initialState = "";

  const subCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUB_CATEGORY:
            return action.payload
        default: return state;
    }
}

export default subCategoryReducer;