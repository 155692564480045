import React, { useState } from "react";
import "./dasboard.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
// import { TbMenuOrder } from 'react-icons/tb'
import {
  BiBell,
  BiSearch,
  BiSolidMessageDetail,
  BiSolidLockOpen,
  BiLogOut,
} from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { AiFillSetting } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../utils/localStrogaeUtil";

export default function Navbar({ sidebarActive, toggleSidebar }) {
  const [profile, setProfile] = useState(false);
  const navigate = useNavigate();

  // const clearAuthData = () => {
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("expiration");
  //     localStorage.removeItem("userId");
  //     localStorage.removeItem("role");
  // }

  const userLogoutOnClick = () => {
    clearLocalStorage();
    navigate("/login");
  };
  console.log(sidebarActive, "sidebar");
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <button
            type="button"
            onClick={toggleSidebar}
            className="btn"
            style={{ background: "#6d7fcc", color: "white" }}
          >
            {!sidebarActive ? (
              <RiMenuFoldFill style={{ fontSize: "1.5rem" }} />
            ) : (
              <RiMenuUnfoldFill style={{ fontSize: "1.5rem" }} />
            )}
          </button>

          <div className=" ">
            <ul
              className="d-flex align-items-center"
              style={{ listStyle: "none", marginBottom: "0" }}
            >
              <li className="nav-item active">
                <a className="nav-link" href="#">
                  <BiBell style={{ fontSize: "1.5rem" }} />
                </a>
              </li>
              <li className="nav-item ">
                <div className="nav-searchBar">
                  <BiSearch style={{ fontSize: "1.5rem", margin: "0 .5rem" }} />
                  <input type="search" name="" id="" />
                </div>
              </li>
              <li className="nav-item ">
                <a
                  className="nav-link"
                  onClick={() => {
                    setProfile(!profile);
                  }}
                >
                  <CgProfile style={{ fontSize: "1.5rem" }} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {profile && (
        <ul className="profile-dropdown">
          <li>
            <AiFillSetting className="mr-2" />
            Setting
          </li>
          <li>
            <BsFillPersonFill className="mr-2" />
            My profile
          </li>
          <li>
            <BiSolidMessageDetail className="mr-2" />
            My Message
          </li>
          <li>
            <BiSolidLockOpen className="mr-2" />
            Lock Screen
          </li>
          <li onClick={() => userLogoutOnClick()} className="logout-button">
            <BiLogOut className="mr-2" />
            Log Out
          </li>
        </ul>
      )}
    </div>
  );
}
