import { getDataAPI, postDataAPI, postFormDataAPI } from "../../services/apiWrapper";
import { toast } from "react-toastify";
import { clearLocalStorage } from "../../utils/localStrogaeUtil";

export const newUserRegister = (obj) => async (dispatch) => {
    try {
        const response = await dispatch(postFormDataAPI(`user/register`, obj));

        if (response) {
            toast.success("User Added successfully!");
            return response.data;
        }
    } catch (error) {
        if (error.response.status === 401) {
            clearLocalStorage();
        } else {
            toast.error('Fill all the details!');
        }
        //   dispatch({ type: ALERT, payload: { loading: false } });
        return false;
    }
};

export const userLogin = (obj) => async (dispatch) => {
    try {
        console.log(obj, " : Payload");
        const response = await dispatch(postDataAPI(`user/login`, obj));
        if (response) {
            toast.success("Login Successfull!");
            return response.data;
        }
    } catch (error) {
        toast.error('Login failed!');
        return false;
    }
}

export const getUserList = () => async (dispatch) => {
    try {
        const response = await dispatch(getDataAPI('user/userlist'));
        if (response) {
            // toast.success("Login Successfull!");
            return response.data;
        }
    } catch (error) {
        toast.error("API call failed!");
        return false;
    }
}