import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newUserRegister } from "../../../store/Action/usersAction";
import { toast } from "react-toastify";
import "./signin.css";

export default function SignIn() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [userProfileImage, setUserProfileImage] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState("");
  const [bannerFileSizeError, setBannerFileSizeError] = useState(false);

  const filePicker = useRef(null); // Define the filePicker ref

  const onBannerImagePicked = (event) => {
    const fileInput = filePicker.current; // Access the filePicker ref
    const file = fileInput.files[0];
    const maxSizeInKB = 1024; // Define your maximum size in KiloBytes (KB)

    if (file) {
      const fileSizeInKB = file.size / 1024;

      if (fileSizeInKB > maxSizeInKB) {
        // File size exceeds the allowed limit
        alert("Logo image size exceeds the maximum allowed size.");
        fileInput.value = ""; // Clear the input field
        setBannerImagePreview("");
        setBannerFileSizeError(true);
      } else {
        setUserProfileImage(file);
        const reader = new FileReader();
        reader.onload = () => {
          setBannerImagePreview(reader.result);
          setBannerFileSizeError(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const dispatch = useDispatch();

  // handler Role selection
  const handleRoleChange = (role) => {
    setRole(role);
  }

  const handleSubmit = async (event) => {
    console.log("Handle submit clicked");
    console.log(name, " : Name");
    console.log(email, " : Email");
    console.log(password, " : Password");
    console.log(confirmPassword, " : Confirm Password");
    console.log(role, " : Role");

    //
    event.preventDefault();
    if (!role) {
      toast.error("Please provide a role!");
      return;
    }
    if (!name) {
      toast.error("Please provide the Admin's Name");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Password & Confirm Password didn't match!");
      return;
    }
    let payload = {
      name: name,
      email: email,
      password: password,
      role: role
    }

    console.log(userProfileImage, " : userProfileImage");

    if (userProfileImage) {
      payload.userProfileImage = userProfileImage;
      // for (var i = 0; i < this.form.value.backgroundimg.length; i++) {
      //   postData.append("backgroundimg", this.form.value.backgroundimg[i]);
      // }
    }

    const response = await dispatch(newUserRegister(payload));

    console.log(response, " :::: **Response");
  }

  return (
    <div className="login-container">
      {/* <div className=""> */}
      {/* <div className="line"></div> */}
      <div className="card">
        <div className="card-header">Register</div>
        <div className="card-body">
          <form className="col-12">
            <div className="form-group">
              <label htmlFor="nameFormControl">Name</label>
              <input
                type="text"
                className="form-control"
                id="nameFormControl"
                placeholder="Name of the Admin"
                onChange={(event) => setName(event.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="emailFormControl">Email Address</label>
              <input
                type="email"
                className="form-control"
                id="emailFormControl"
                placeholder="Email Address"
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="passwordFormControl">Password</label>
              <input
                type="password"
                className="form-control"
                id="passwordFormControl"
                placeholder="Password"
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="confirmPasswordFormControl">confirm Password</label>
              <input
                type="password"
                className="form-control"
                id="confirmPasswordFormControl"
                placeholder="confirm Password"
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="exampleText1">Role</label>
              <input
                type="text"
                className="form-control"
                id="exampleText1"
                aria-describedby="emailHelp"
                placeholder="Enter your role"
              /> */}
              <label htmlFor="roleFormControl">
                Select Role
              </label>
              <select
                className="form-control"
                id="roleFormControl"
                value={role}
                onChange={(event) => {
                  handleRoleChange(event.target.value);
                }}
              >
                <option value="">Select a Role</option>
                <option value="superadmin">Super Admin</option>
                <option value="admin">Admin</option>
              </select>
            </div>

            {/* Image Picker Starts Here */}
            <div className="form-group">
              <label htmlFor="exampleText1">Upload Image</label>
              <div className="add-img">
                <button
                  type="button"
                  onClick={() => filePicker.current.click()} // Access the filePicker ref
                  className="col-4"
                >
                  + Image
                </button>
                <input
                  type="file"
                  ref={filePicker}
                  onChange={onBannerImagePicked}
                />
                {bannerFileSizeError && (
                  <div className="error-message">
                    File size exceeds the maximum allowed size.
                  </div>
                )}
              </div>
              <br />

              {/* {(bannerImagePreview || s3ImageId) && (
                  <div className="image-preview">
                    <img src={bannerImagePreview ? bannerImagePreview : `https://stage-api.iirfranking.com/s3-images/${s3ImageId}`} alt={'test alt name'} />
                  </div>
                )} */}
              {bannerImagePreview ? (
                <div className="image-preview">
                  <img
                    src={
                      bannerImagePreview
                    }
                    alt="test alt name"
                  />
                </div>
              ) : null}
            </div>
            {/* Image Picker Ends Here */}
            <br />

            <button type="button" className="btn btn-danger btn-lg col-4" onClick={handleSubmit}>
              Register
            </button>

          </form>
        </div>
      </div>
    </div>
    // </div>
  );
}
