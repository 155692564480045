import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import "./newsEmbedding.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  deleleteNews,
  getNews,
  getNewsByCategory,
} from "../../../store/Action/embeddingAction/newsEmbeddingAction";
import moment from "moment";
import { getCategory } from "../../../store/Action/categoryAction";
export default function ManageNewsEmbedding() {
  const dispatch = useDispatch();
  const [news, setNews] = useState([]);
  const [deletes, setDelete] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [parentCategory, setParentCategory] = useState("");
  const [filterData, setFilterData] = useState([]);
  const itemsPerPage = 12; // Change this to the desired number of items per page
  const {
    categoryData,
    // subCategoryData,
    // subNextCategoryReducer,
    // newSubCategory,
  } = useSelector((state) => state);

  // Logic to calculate total pages
  // const queryParams = { page: currentPage, perPage: itemsPerPage };
  const handleParentCategoryChange = (selectedParentCategory) => {
    setParentCategory(selectedParentCategory);
  };

  // Fetch News and total count from API or any data source
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const response = dispatch(deleleteNews(id));
      if (response) {
        setDelete(response);
      }
    }
  };

  const queryParams = useMemo(() => {
    return { page: currentPage, perPage: itemsPerPage }
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await dispatch(getNews(queryParams));
        if (response) {
          setNews(response.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (queryParams) {
      fetchNews();
    }
  }, [dispatch, deletes, currentPage, queryParams]);

  // Logic to handle pagination controls
  const nextPage = () => {
    if (news.length === itemsPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // use effect for filter

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          categoryid: parentCategory,
          page: currentPage,
          limit: itemsPerPage,
        };

        const response = await dispatch(getNewsByCategory(params));

        if (response) {
          setFilterData(response?.data);
        } else {
          setFilterData([]);
        }
      } catch (error) {
        setFilterData([]);
      }
    };

    // Only fetch data if parentCategory is defined
    if (parentCategory) {
      fetchData();
    }
  }, [
    parentCategory,
    currentPage,
    itemsPerPage,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  console.log(
    categoryData,
    "data"
  );

  useEffect(() => {
    if (categoryData) {
      let item = categoryData?.data[0]?._id;
      setParentCategory(item);
    }
  }, [categoryData])

  return (
    <div>
      <div className="card">
        <div className="card-header">Manage News Embeddings</div>
        <div className="filter-container">
          <div className="form-group">
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              value={parentCategory}
              onChange={(event) => {
                handleParentCategoryChange(event.target.value);
              }}
            >
              <option value="">Select Category</option>
              {categoryData?.data?.map((item, i) => (
                <option key={i} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="card-body">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Category</th>
                <th scope="col">Heading</th>
                <th scope="col">Url</th>
                <th scope="col">Description</th>
                <th scope="col">Publish Date</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            {filterData ? (
              <tbody>
                {filterData.map((item) => (
                  <tr key={item._id}>
                    <td className="news-items">
                      {item.categoryid.name}
                    </td>

                    <td className="news-items">{item.heading}</td>
                    <td className="news-items">{item.url}</td>
                    <td className="news-description">
                      {item.description.length > 120
                        ? `${item.description.slice(0, 120)} ...`
                        : `${item.description}`}
                    </td>
                    <td className="news-items">
                      <small>
                        {moment(item.publishDate).format("DD-MM-YYYY")}
                      </small>
                    </td>
                    <td className="news-items">
                      {item.status == 1 ? "Active" : "Inactive"}
                    </td>
                    <td className="d-flex">
                      <Link to={`/editnewsembedding/${item._id}`}>
                        <button
                          className="action-button"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </Link>

                      <button
                        className="action-button"
                        onClick={() => handleDelete(item._id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {news.map((item) => (
                  <tr key={item._id}>
                    <td className="news-items">
                      {item.categoryid?.name}
                    </td>

                    <td className="news-items">{item.heading}</td>
                    <td className="news-items">{item.city}</td>
                    <td className="news-items">{item.url}</td>
                    <td className="news-description">
                      {item.description.length > 120
                        ? `${item.description.slice(0, 120)} ...`
                        : `${item.description}`}
                    </td>
                    <td className="news-items">
                      <small>
                        {moment(item.publishDate).format("DD-MM-YYYY")}
                      </small>
                    </td>
                    <td className="news-items">
                      {item.status == 1 ? "Active" : "Inactive"}
                    </td>
                    <td className="d-flex">
                      <Link to={`/editnewsembedding/${item._id}`}>
                        <button
                          className="action-button"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </Link>

                      <button
                        className="action-button"
                        onClick={() => handleDelete(item._id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                    {/* Add action buttons here */}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          <div className="card-footer bg-transparent border-secondary d-flex justify-content-center">
            <div className="pagination">
              <button
                className="pagination-btn"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="current-page">{currentPage}</span>

              <button
                className="pagination-btn"
                onClick={nextPage}
                disabled={news.length < itemsPerPage}
              >
                {news.length < itemsPerPage ? "" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
