import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useState } from "react";
import "./App.css";
import AddCategory from "./Component/Dashboard/category/AddCategory";
import ManageCategory from "./Component/Dashboard/category/ManageCategory";
import AddNews from "./Component/Dashboard/news/AddNews";
import ManageNews from "./Component/Dashboard/news/ManageNews";
import AddBlogsCategory from "./Component/Dashboard/blogsCategory/AddBlogsCategory";
import ManageBlogsCategory from "./Component/Dashboard/blogsCategory/ManageBlogsCategory";
import Navbar from "./Component/Navbar";
import Sidebar from "./Component/Sidebar";
import Dashboard from "./Component/Dashboard";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LogIn from "./Component/Dashboard/logIn/LogIn";
import SignIn from "./Component/Dashboard/signIn/SignIn";
import AddBlogs from "./Component/Dashboard/blogs/AddBlogs";
import ManageBlogs from "./Component/Dashboard/blogs/ManageBlogs";
import AddNewsEmbedding from "./Component/Dashboard/newsEmbedding/AddNewsEmbedding";
import ManageNewsEmbedding from "./Component/Dashboard/newsEmbedding/ManageNewsEmbedding";
import ImportNewsEmbedding from "./Component/Dashboard/newsEmbedding/ImportNewsEmbedding";
import TrackerNews from "./Component/Dashboard/news/TrackerNews";
import AddVideos from "./Component/Dashboard/video/AddVideos";
import ManageVideos from "./Component/Dashboard/video/ManageVideos";
import AddMagzine from "./Component/Dashboard/magzine/AddMagzine";
import ManageMagzine from "./Component/Dashboard/magzine/ManageMagzine";

const ProtectedRoute = ({ element, sidebarActive, toggleSidebar }) => {
  // console.log(element, " : Element");
  console.log(sidebarActive, " : sidebarActive");
  const token = localStorage.getItem('token');
  // Check if the user is authenticated
  if (!token) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/login" />;
  }

  // Render the protected component with Sidebar and Navbar
  return (
    <>
      <Sidebar sidebarActive={sidebarActive} toggleSidebar={toggleSidebar} />
      <div id="content">
        <Navbar sidebarActive={sidebarActive} toggleSidebar={toggleSidebar} />
        {element}
      </div>
    </>
  );
};


function App() {
  const [sidebarActive, setSidebarActive] = useState(false);

  // Function to toggle the sidebar
  const toggleSidebar = () => {
    console.log("Toggle Sidebar clicked");
    setSidebarActive(!sidebarActive);
  };

  return (
    <div>
      <ToastContainer />
      <Router>
        <div className="wrapper">
          <Routes>
            <Route exact path="/" element={<ProtectedRoute element={<Dashboard />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/addcategory" element={<ProtectedRoute element={<AddCategory />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/editcategory/:id" element={<ProtectedRoute element={<AddCategory />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/managecategory" element={<ProtectedRoute element={<ManageCategory />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />

            <Route exact path="/addnews" element={<ProtectedRoute element={<AddNews />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/editnews/:id" element={<ProtectedRoute element={<AddNews />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/trackernews" element={<ProtectedRoute element={<TrackerNews />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/managenews" element={<ProtectedRoute element={<ManageNews />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />

            {/* Section for News Embeddings Starts here  */}
            <Route exact path="/addnewsembedding" element={<ProtectedRoute element={<AddNewsEmbedding />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/editnewsembedding/:id" element={<ProtectedRoute element={<AddNewsEmbedding />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/managenewsembedding" element={<ProtectedRoute element={<ManageNewsEmbedding />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/importnewsembedding" element={<ProtectedRoute element={<ImportNewsEmbedding />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            {/* Section for News Embeddings Ends here  */}

            <Route exact path="/addblogscategory" element={<ProtectedRoute element={<AddBlogsCategory />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/editblogscategory/:id" element={<ProtectedRoute element={<AddBlogsCategory />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/manageblogscategory" element={<ProtectedRoute element={<ManageBlogsCategory />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />

            <Route exact path="/addblogs" element={<ProtectedRoute element={<AddBlogs />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/editblogs/:id" element={<ProtectedRoute element={<AddBlogs />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            <Route exact path="/manageblogs" element={<ProtectedRoute element={<ManageBlogs />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />

            <Route
              exact
              path="/addvideos"
              element={
                <ProtectedRoute
                  element={<AddVideos />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/editvideos/:id"
              element={
                <ProtectedRoute
                  element={<AddVideos />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/managevideos"
              element={
                <ProtectedRoute
                  element={<ManageVideos />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/addmagzine"
              element={
                <ProtectedRoute
                  element={<AddMagzine />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/editmagzine/:id"
              element={
                <ProtectedRoute
                  element={<AddMagzine />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/managemagzine"
              element={
                <ProtectedRoute
                  element={<ManageMagzine />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />

            <Route exact path="/register" element={<ProtectedRoute element={<SignIn />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} />
            {/* <Route exact path="/managenextsubcategory" element={<ProtectedRoute element={<ManageNextCategoryNew />} sidebarActive={sidebarActive}
              toggleSidebar={toggleSidebar} />} /> */}
            {/* <Route exact path="/register" element={<SignIn />} /> */}
            <Route exact path="/login" element={<LogIn />} />
          </Routes>
          {/* </div> */}
        </div>
      </Router>
    </div>
  );
}

export default App;
