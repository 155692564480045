import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
// import {
//   postNewsEmbedding
// } from "../../../store/Action/embeddingAction/newsEmbeddingAction";
// import { getCategory } from "../../../store/Action/categoryAction";
import "./newsEmbedding.css";
// import { useParams, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import axios from "axios";
// import moment from "moment";
// import { postFileDataAPI } from "../../../services/apiWrapper";

export default function ImportNewsEmbedding() {
  // const filePicker = useRef(null); // Define the filePicker ref
  // const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);

  const [adminRole, setAdminRole] = useState("");
  const [author, setAuthor] = useState("");
  const [userList, setUserList] = useState([]);

  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  // Function for fetching and setting te User Id
  useEffect(() => {
    const mytoken = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const role = localStorage.getItem("role");
    setAdminRole(role);
    setAuthor(userId);

    const headers = { Authorization: `Bearer ${mytoken}` };
    axios.get(`${apiUrl}user/userlist`, { headers }).then((response) => {
      console.log(response, " : &&&&&response");
      setUserList(response.data.data);
      setTimeout(() => {
      }, 10);
    })
      .catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          localStorage.removeItem("role");
        }
      })
  }, [apiUrl]);

  // const handleHeadingChange = (event) => {
  //   const inputValue = event.target.value;

  //   if (inputValue.length <= 100) {
  //     setHeading(inputValue);
  //   } else {
  //     // You can handle the case where the input exceeds the maximum character limit, such as showing an error message.
  //     toast.error("Input exceeds the maximum character limit (100)");
  //   }
  // };

  // const handleSubHeadingChange = (event) => {
  //   const inputValue = event.target.value;

  //   if (inputValue.length <= 100) {
  //     setSubHeading(inputValue);
  //   } else {
  //     // You can handle the case where the input exceeds the maximum character limit, such as showing an error message.
  //     toast.error("Input exceeds the maximum character limit (100)");
  //   }
  // };

  // const handleUrlChange = (event) => {
  //   let value = event.target.value;

  //   // Remove special characters except hyphen
  //   value = value.replace(/[^a-zA-Z0-9- ]/g, '');

  //   // Replace spaces with hyphens
  //   value = value.replace(/\s+/g, '-');

  //   // Convert to lowercase
  //   value = value.toLowerCase();
  //   // Update the state with the sanitized value
  //   setUrl(value);
  // };

  // const handleDateChange = (event) => {
  //   const selectedDate = event.target.value;
  //   const formattedDate = moment(selectedDate, "YYYY-MM-DDTHH:mm").toDate();

  //   if (formattedDate) {
  //     setPublishDate(formattedDate);
  //   }

  //   console.log(publishDate, "datechange");
  // };

  // On banner Image Picked
  // const onBannerImagePicked = () => {
  //   const fileInput = filePicker.current; // Access the filePicker ref
  //   const file = fileInput.files[0];
  //   const maxSizeInKB = 1024; // Define your maximum size in KiloBytes (KB)

  //   if (file) {
  //     const fileSizeInKB = file.size / 1024;
  //     if (fileSizeInKB > maxSizeInKB) {
  //       // File size exceeds the allowed limit
  //       alert("Logo image size exceeds the maximum allowed size.");
  //       fileInput.value = ""; // Clear the input field
  //       setBannerImagePreview("");
  //       setBannerFileSizeError(true);
  //     } else {
  //       setSelectedBannerImageFile(file);
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         setBannerImagePreview(reader.result);
  //         setBannerFileSizeError(false);
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   }
  // };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImport = async () => {
    if (!selectedFile) {
      alert("Please select a file.");
      return;
    }
    let token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append("jsonfile", selectedFile);
    axios
      .post(`${apiUrl}newsembedding/import-epn-json`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle successful response
        console.log("Import successful:", response.data);
        if (response.data.payload.length) {
          handleImport();
        }
        // setSelectedFile(null);
        // window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.error("Error occurred during import:", error);
        // setSelectedFile(null);
        // window.location.reload();
      });
  };

  return (
    <div>
      <div className="addnews">
        <div className="line"></div>
        <div className="card ">
          <div className="card-header">{"Import News Embeddings"}</div>
          <div className="card-body">
            <div className="import-container">
              <input
                type="file"
                accept=".json"
                onChange={handleFileChange}
              />
              <button
                type="button"
                className="btn btn-primary btn-lg my-3"
                onClick={handleImport}
              >
                {"Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
