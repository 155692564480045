import {
  getDataAPI,
  deleteData,
  postFormDataAPI,
  patchFormDataApi,
} from "../../services/apiWrapper";
import axios from "axios";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const postNewsAdd = (obj) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(postFormDataAPI(`news`, obj));
    if (response) {
      toast.success("News Added successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    // console.log(error, " : Error");
    toast.error(error.response.data.message);
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const getNewsByCategory = (obj) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrl}news/bycategory`, obj, {
      params: {
        page: obj.page,
        limit: obj.limit,
      },
    });

    if (response && response.data) {
      // Assuming response.data contains the necessary information
      return response.data;
    } else {
      throw new Error("Invalid response from the server");
    }
  } catch (error) {
    // console.error("Error in getNewsByCategory:", error);
    // Assuming toast is defined somewhere else in your code
    toast.error("No Data Found");
    return false;
  }
};

// export const getNewsByCategory =
//   (obj) =>
//   async (dispatch) => {
//     try {
//       // Assuming postFormDataAPI is an async action creator
//       // If it's not, you may need to call it directly or modify accordingly
//       console.log(obj,"objjjjjjj")
//       const response = await dispatch(
//         postFormDataAPI(`news/bycategory`,obj)
//       );

//       if (response && response.data) {
//         // toast.success("News Added successfully");
//         // Assuming response.data contains the necessary information
//         return response.data;
//       } else {
//         throw new Error("Invalid response from the server");
//       }
//     } catch (error) {
//       console.error("Error in getNewsByCategory:", error);
//       toast.error("Fill all the details!");
//       return false;
//     }
//   };

export const getNews = (obj) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      getDataAPI(`news?page=${obj.page}&limit=${obj.perPage}`)
    );
    if (response) {
      // toast.success("News Added successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    // toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
export const deleleteNews = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(deleteData(`news/${id}`));
    if (response) {
      toast.success("News deleted Successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("Something went wrong");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
export const getNewsId = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI(`news/${id}`));
    if (response) {
      return response.data.data;
    }
  } catch (error) {
    // toast.error('please fill all field');
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const postNewsUpdate = (id, data) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(patchFormDataApi(`news/${id}`, data));
    if (response) {
      toast.success("News updated Successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error(error.message);
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
