export const ALERT = "ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REMOVE_ALERTS = "REMOVE_ALERT";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_BLOG_CATEGORY = "GET_BLOG_CATEGORY";
export const GET_VIDEOS = "GET_VIDEOS";
export const GET_MAGZINE = "GET_MAGZINE";
export const SET_CONTENT = "SET_CONTENT";

// Out Dated
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";
export const GET_NEW_SUB_CATEGORY = "GET_NEW_SUB_CATEGORY";
export const GET_SECONDARY_SUB_CATEGORY = "GET_SECONDARY_SUB_CATEGORY";
export const GET_NEXT_SECONDARY_SUB_CATEGORY = "GET_NEXT_SECONDARY_SUB_CATEGORY";
// Out Dated