import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postNewsAdd,
  postNewsUpdate,
  getNewsId,
} from "../../../store/Action/embeddingAction/newsEmbeddingAction";
import { getCategory } from "../../../store/Action/categoryAction";
import "./newsEmbedding.css";
import { useParams, useNavigate } from "react-router-dom";
import Editor from "../../../DecoupledEditor";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";

export default function AddNewsEmbedding() {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [content, setContent] = useState("");

  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [url, setUrl] = useState("");
  const [author, setAuthor] = useState("");

  const [adminRole, setAdminRole] = useState("");
  const [description, setDescription] = useState("");

  const [s3ImageId, setS3ImageId] = useState("");
  const [selectedBannerImageFile, setSelectedBannerImageFile] = useState(null);
  const [bannerImgCaption, setBannerImgCaption] = useState("");
  const [bannerImgAlt, setBannerImgAlt] = useState("");

  const [bannerImagePreview, setBannerImagePreview] = useState("");
  const [bannerFileSizeError, setBannerFileSizeError] = useState(false);
  const [categoryid, setCategoryid] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDecription] = useState("");
  const [country, setCountry] = useState("");
  const [stateOrProvince, setStateOrProvince] = useState("");
  const [city, setCity] = useState("");
  const [status, setStatus] = useState('1');

  const [publishDate, setPublishDate] = useState(new Date());

  const [keyWords, setKeyWords] = useState("");
  const [userList, setUserList] = useState([]);

  const filePicker = useRef(null); // Define the filePicker ref

  const dispatch = useDispatch();
  // const today = moment().format("YYYY-MM-DDTHH:mm");

  const { categoryData } = useSelector((state) => state);
  const id = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setEditorLoaded(true);
    dispatch(getCategory());
  }, [dispatch]);

  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const mytoken = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const role = localStorage.getItem("role");
    setAdminRole(role);
    setAuthor(userId);

    const headers = { Authorization: `Bearer ${mytoken}` };
    axios.get(`${apiUrl}user/userlist`, { headers }).then((response) => {
      console.log(response, " : &&&&&response");
      setUserList(response.data.data);
      setTimeout(() => {
      }, 10);
    })
      .catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          localStorage.removeItem("role");
        }
      })
  }, [apiUrl]);

  const handleAuthorChange = (author) => {
    setAuthor(author);
  };

  const handleCategoryChange = (value) => {
    setCategoryid(value);
  }

  const handleMetaTitleChange = (event) => {
    let value = event.target.value;
    setMetaTitle(value)
  }

  const handleMetaDescription = (event) => {
    let value = event.target.value;
    setMetaDecription(value);
  }

  const handleCountry = (event) => {
    let value = event.target.value;
    setCountry(value);
  }

  const handleStatusChange = (value) => {
    setStatus(value);
  }

  const handleStateOrProvince = (event) => {
    let value = event.target.value;
    setStateOrProvince(value);
  }

  const handleCity = (event) => {
    let value = event.target.value;
    setCity(value);
  }

  const handleKeyWords = (event) => {
    let value = event.target.value;
    setKeyWords(value);
  }

  const handleDescription = (event) => {
    let value = event.target.value;
    setDescription(value);
  }

  const handleBannerImgCaptionChange = (event) => {
    setBannerImgCaption(event.target.value);
  };

  const handleAltImgChange = (event) => {
    setBannerImgAlt(event.target.value);
  };

  const handleHeadingChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 100) {
      setHeading(inputValue);
    } else {
      // You can handle the case where the input exceeds the maximum character limit, such as showing an error message.
      toast.error("Input exceeds the maximum character limit (100)");
    }
  };

  const handleSubHeadingChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 350) {
      setSubHeading(inputValue);
    } else {
      // You can handle the case where the input exceeds the maximum character limit, such as showing an error message.
      toast.error("Input exceeds the maximum character limit (350)");
    }
  };

  const handleUrlChange = (event) => {
    let value = event.target.value;

    // Remove special characters except hyphen
    value = value.replace(/[^a-zA-Z0-9- ]/g, '');

    // Replace spaces with hyphens
    value = value.replace(/\s+/g, '-');

    // Convert to lowercase
    value = value.toLowerCase();
    // Update the state with the sanitized value
    setUrl(value);
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    const formattedDate = moment(selectedDate, "YYYY-MM-DDTHH:mm").toDate();

    if (formattedDate) {
      setPublishDate(formattedDate);
    }

    console.log(publishDate, "datechange");
  };

  // On banner Image Picked
  const onBannerImagePicked = () => {
    const fileInput = filePicker.current; // Access the filePicker ref
    const file = fileInput.files[0];
    const maxSizeInKB = 1024; // Define your maximum size in KiloBytes (KB)

    if (file) {
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > maxSizeInKB) {
        // File size exceeds the allowed limit
        alert("Logo image size exceeds the maximum allowed size.");
        fileInput.value = ""; // Clear the input field
        setBannerImagePreview("");
        setBannerFileSizeError(true);
      } else {
        setSelectedBannerImageFile(file);
        const reader = new FileReader();
        reader.onload = () => {
          setBannerImagePreview(reader.result);
          setBannerFileSizeError(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // Fetch the news data form The Id
  useEffect(
    (i) => {
      const fetchNews = async () => {
        try {
          const resp = await dispatch(getNewsId(id.id));
          if (resp) {
            if (resp.categoryid) {
              setCategoryid(resp?.categoryid?._id)
            }
            setHeading(resp?.heading);
            setSubHeading(resp?.subHeading);
            setUrl(resp?.url);
            setMetaTitle(resp?.metaTitle);
            setMetaDecription(resp?.metaDescription);
            setKeyWords(resp?.keyWords);

            setDescription(resp?.description);
            setContent(resp?.content);

            setCountry(resp?.country);
            setStateOrProvince(resp?.stateOrProvince);
            setCity(resp?.city);

            setStatus(resp?.status);

            if (resp.author) {
              setAuthor(resp?.author._id);
            }
            setS3ImageId(resp?.bannerImage);
            setBannerImgCaption(resp?.bannerImageCaption);
            setBannerImgAlt(resp?.bannerImgAlt);
            setPublishDate(resp?.publishDate ? resp?.publishDate : "");
          }
        } catch (error) {
        }
      };
      if (id.id) {
        setContent('');
        fetchNews();
      }
      // else {
      //   setCategoryid("");
      //   setHeading("");
      //   setSubHeading("");
      //   setUrl("");
      //   setMetaTitle("");
      //   setMetaDecription("");
      //   setKeyWords("");
      //   setDescription("");
      //   setContent("");
      //   setCountry("");
      //   setStateOrProvince("");
      //   setCity("");
      //   setStatus("");
      //   setAuthor("");
      //   setPublishDate(new Date());

      //   setSelectedBannerImageFile(null);
      //   setEditorLoaded(true);
      //   console.log("id is not find");
      // }
    },
    [dispatch, id]
  );

  // Function to submit the form data
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!id.id) {
      let payload = {
        categoryid: categoryid,
        heading: heading,
        subHeading: subHeading,
        url: url,

        metaTitle: metaTitle,
        metaDescription: metaDescription,
        keyWords: keyWords,

        description: description,
        content: content,

        country: country,
        stateOrProvince: stateOrProvince,
        city: city,

        status: status,
        author: author,

        publishDate: publishDate,
      };

      if (selectedBannerImageFile) {
        payload.bannerImage = selectedBannerImageFile;
        payload.bannerImageCaption = bannerImgCaption;
        payload.bannerImgAlt = bannerImgAlt;
        // for (var i = 0; i < this.form.value.backgroundimg.length; i++) {
        //   postData.append("backgroundimg", this.form.value.backgroundimg[i]);
        // }
      }

      const response = await dispatch(postNewsAdd(payload));
      if (response) {
        setDescription("");
        setHeading("");
        setUrl("");
        setS3ImageId("");
        setSelectedBannerImageFile(null);
        setContent("");
        navigate("/managenewsembedding");
        // toast.sucess('')
      }
    } else {
      let payload = {
        categoryid: categoryid,
        heading: heading,
        subHeading: subHeading,
        url: url,

        metaTitle: metaTitle,
        metaDescription: metaDescription,
        keyWords: keyWords,

        description: description,
        content: content,

        country: country,
        stateOrProvince: stateOrProvince,
        city: city,

        status: status,
        author: author,

        publishDate: publishDate,
      };

      if (selectedBannerImageFile) {
        payload.bannerImage = selectedBannerImageFile;
        payload.bannerImageCaption = bannerImgCaption;
        payload.bannerImgAlt = bannerImgAlt;
        // for (var i = 0; i < this.form.value.backgroundimg.length; i++) {
        //   postData.append("backgroundimg", this.form.value.backgroundimg[i]);
        // }
      }

      await dispatch(postNewsUpdate(id.id, payload));
      navigate("/managenewsembedding");
    }
  };

  return (
    <div>
      <div className="addnews">
        <div className="line"></div>
        <div className="card ">
          <div className="card-header">{id.id ? "Edit News Embeddings" : "Add News Embeddings"}</div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="exampleFormControlSelect1">
                    Select Category <span className="req-feild">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={categoryid}
                    onChange={(event) => {
                      handleCategoryChange(event.target.value);
                    }}
                  >
                    <option value="">Select Category</option>
                    {categoryData?.data?.map((item, i) => (
                      <option key={i} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="heading">
                    Heading <span className="req-feild">*</span>
                    <small>(Max 100 character)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="heading"
                    placeholder="Heading"
                    value={heading}
                    onChange={handleHeadingChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="subHeading">
                    Sub Heading
                    <small>(Max 350 character)</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subHeading"
                    placeholder="Sub Heading"
                    value={subHeading}
                    onChange={handleSubHeadingChange}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="exampleText1">
                    URL<span className="req-feild">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleText1"
                    aria-describedby="emailHelp"
                    placeholder="Url"
                    value={url}
                    onChange={handleUrlChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="metaTitle">
                    Meta Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="metaTitle"
                    placeholder="Meta Title"
                    value={metaTitle}
                    onChange={handleMetaTitleChange}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="metaDescription">
                    Meta Description
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="metaDescription"
                    placeholder="Meta Description"
                    value={metaDescription}
                    onChange={handleMetaDescription}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="country">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    placeholder="Country"
                    value={country}
                    onChange={handleCountry}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="stateOrProvince">
                    State Or Province
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="stateOrProvince"
                    placeholder="State Or Province"
                    value={stateOrProvince}
                    onChange={handleStateOrProvince}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="city">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    placeholder="City"
                    value={city}
                    onChange={handleCity}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="status">
                    Status
                    <span className="req-feild">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="status"
                    value={status}
                    onChange={(event) => {
                      handleStatusChange(event.target.value);
                    }}
                  >
                    <option value={"1"}>
                      Active
                    </option>
                    <option value={"0"}>
                      Inactive
                    </option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="keyWords">
                    Key Words
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="keyWords"
                    placeholder="Key Words"
                    value={keyWords}
                    onChange={handleKeyWords}
                  />
                </div>
              </div>

              {/* Writer Pick starts here  */}
              {adminRole === "superadmin" && (
                <div className="form-group">
                  <label htmlFor="writerPick">
                    Select/Change the Author{" "}
                    <span className="req-feild">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="writerPick"
                    value={author}
                    onChange={(event) => {
                      handleAuthorChange(event.target.value);
                    }}
                  >
                    {userList.map((item, i) => (
                      <option key={i} value={item._id}>
                        {item.name ? item.name : item.email}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {/* Writer Pick ends here  */}

              {/* Image Picker Starts Here */}
              <div className="news-img-container">
                <div className="form-group ">
                  <label htmlFor="exampleText1">Upload Image</label>
                  <div className="add-img">
                    <button
                      type="button"
                      onClick={() => filePicker.current.click()} // Access the filePicker ref
                    >
                      + Image
                    </button>
                    <input
                      type="file"
                      ref={filePicker}
                      onChange={onBannerImagePicked}
                    />
                    {bannerFileSizeError && (
                      <div className="error-message">
                        File size exceeds the maximum allowed size.
                      </div>
                    )}
                  </div>

                  {bannerImagePreview || s3ImageId ? (
                    <div className="news-banner-image-preview">
                      <img
                        src={
                          bannerImagePreview
                            ? bannerImagePreview
                            : `http://localhost:8080/s3-images/${s3ImageId}`
                        }
                        alt="test alt name"
                      />
                    </div>
                  ) : null}
                </div>

                {(bannerImagePreview || s3ImageId) && (
                  <>
                    <div className="form-group">
                      <label htmlFor="exampleText1">Image Caption</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleText1"
                        aria-describedby="emailHelp"
                        placeholder="image caption"
                        value={bannerImgCaption}
                        onChange={handleBannerImgCaptionChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleText1">Image Alt.</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleText1"
                        aria-describedby="emailHelp"
                        placeholder="image alt"
                        value={bannerImgAlt}
                        onChange={handleAltImgChange}
                      />
                    </div>
                  </>
                )}
              </div>
              {/* Image Picker Ends Here */}

              <div className="form-group">
                <label htmlFor="exampleText1">
                  Publish Date
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  required
                  value={moment(publishDate).format("YYYY-MM-DDTHH:mm")}
                  onChange={handleDateChange}
                  max={moment(publishDate).format("YYYY-MM-DDTHH:mm")}
                />
              </div>

              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="description">
                    Description
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Description"
                    value={description}
                    onChange={handleDescription}
                  />
                </div>
              </div>

              <div className="form-group ck-editor-container">
                <label htmlFor="exampleText1">
                  Content <span className="req-feild">*</span>
                </label>
                <Editor
                  name="description"
                  value={content}
                  onChange={(content) => {
                    setContent(content);
                  }}
                  editorLoaded={editorLoaded}
                />
              </div>
            </form>

            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-danger btn-lg my-3"
            >
              {id.id ? "Update News" : "Add News"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
