import React, { useState, useEffect } from "react";
import "./category.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getCategorys,
  postCategoryAdd,
  postCategoryUpdate,
} from "../../../store/Action/categoryAction";

export default function AddCategory() {
  const [inputValue, setInputValue] = useState("");
  const [navViewOrder, setNavViewOrder] = useState("");
  const [seoUrl, setSeoUrl] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDecription] = useState("");
  const [keyWords, setKeyWords] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("1");

  const dispatch = useDispatch();
  // const history = useHistory();
  const id = useParams();
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handlenavViewOrderChange = (event) => {
    setNavViewOrder(event.target.value);
  }

  const handelSeoUrlChange = (event) => {
    setSeoUrl(event.target.value);
  }

  const handleMetaTitleChangeChange = (event) => {
    setMetaTitle(event.target.value);
  }

  const handleMetaDescriptionChange = (event) => {
    setMetaDecription(event.target.value);
  }

  const handleKeyWordsChange = (event) => {
    setKeyWords(event.target.value);
  }

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = { name: inputValue, navViewOrder: navViewOrder, seoUrl: seoUrl, metaTitle: metaTitle, metaDescription: metaDescription, keyWords: keyWords, status: selectedStatus };
      if (id.id) {
        let response = await dispatch(postCategoryUpdate(id.id, data));
        if (response) {
          navigate("/managecategory");
        }
      } else {
        let response = await dispatch(postCategoryAdd(data));
        if (response) {
          navigate("/managecategory");
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const resp = await dispatch(getCategorys(id.id));
        if (resp) {
          setInputValue(resp.data.data.name);
          setNavViewOrder(resp.data.data.navViewOrder);
          setSeoUrl(resp.data.data.seoUrl);
          setMetaTitle(resp.data.data.metaTitle);
          setMetaDecription(resp.data.data.metaDescription);
          setKeyWords(resp.data.data.keyWords);
          setSelectedStatus(resp.data.data.status);
        }
      } catch (error) {
        // Handle error, e.g., show an error toast message
      }
    };

    if (id.id) {
      fetchCategory();
    }
  }, [dispatch, id.id]);

  return (
    <div>
      <div className="">
        <div className="line"></div>
        <div className="card">
          <div className="card-header">
            {id.id ? "Edit Category" : "Add Category"}
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="exampleText1">Add Category</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleText1"
                    aria-describedby="emailHelp"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Add Category"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="navViewOrder">Navbar View Order</label>
                  <input
                    type="text"
                    className="form-control"
                    id="navViewOrder"
                    value={navViewOrder}
                    onChange={handlenavViewOrderChange}
                    placeholder="Navbar View Order"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="seoUrl">Seo Url</label>
                  <input
                    type="text"
                    className="form-control"
                    id="seoUrl"
                    value={seoUrl}
                    onChange={handelSeoUrlChange}
                    placeholder="SEO Url"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="metaTitle">Meta Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="metaTitle"
                    value={metaTitle}
                    onChange={handleMetaTitleChangeChange}
                    placeholder="Meta Title"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="metaDescription">Meta Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="metaDescription"
                    value={metaDescription}
                    onChange={handleMetaDescriptionChange}
                    placeholder="Meta Description"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="keyWords">Key Words</label>
                  <input
                    type="text"
                    className="form-control"
                    id="keyWords"
                    value={keyWords}
                    onChange={handleKeyWordsChange}
                    placeholder="Key Words"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="exampleFormControlSelect2">Select Status</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect2"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div>
            </form>
            <button
              type="button"
              className="btn btn-danger btn-lg"
              onClick={handleSubmit}
            >
              {id.id ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
