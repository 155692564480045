import { ALERT, GET_MAGZINE, REMOVE_ALERT } from "./actionType";
import {
  getDataAPI,
  postDataAPI,
  deleteData,
  patchDataApi,
} from "../../services/apiWrapper";

import { toast } from "react-toastify";

export const postMagzineAdd = (obj) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(postDataAPI(`magzine`, obj));
    if (response) {
      toast.success("Magzine Added successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const deleteMagzine = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(deleteData(`magzine/${id}`));
    if (response) {
      toast.success("Magzines deleted Successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const getSingleMagzine = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI(`magzine/${id}`));
    if (response) {
      return response;
    }
  } catch (error) {
    // toast.error('please fill all field');
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const postMagzineUpdate = (id, data) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(patchDataApi(`magzine/${id}`, data));
    if (response) {
      toast.success("Magzines updated Successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
