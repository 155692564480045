import React, { useState, useEffect, useRef } from "react";
import "./magzine.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getSingleMagzine,
  postMagzineAdd,
  postMagzineUpdate,
} from "../../../store/Action/magzineAction";

export default function AddMagzine() {
  const [year, setYear] = useState("");
  const [headline, setHeadline] = useState("");
  const [month, setMonth] = useState("");
  const [bannerImageFile, setBannerImageFile] = useState(null);
  const [magazineFile, setMagazineFile] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState("");
  const [bannerFileSizeError, setBannerFileSizeError] = useState(false);
  const [magazineFileSizeError, setMagazineFileSizeError] = useState(false);

  const dispatch = useDispatch();
  const id = useParams();
  const navigate = useNavigate();
  const filePicker = useRef(null);

  // const handleYearChange = (e) => {
  //   setYear(e.target.value);
  // };

  const handleYearChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input value is empty or starts with zero
    if (inputValue === "" || inputValue.charAt(0) === "0") {
      // Show alert message for invalid input
      alert("Please enter a valid year (without leading zeros)");
      // Clear the input field
      setYear("");
      return; // Exit the function early to prevent further processing
    }

    // Check if the input value consists of only digits
    if (/^\d*$/.test(inputValue)) {
      // If so, convert the input value to an integer and update the year state
      setYear(parseInt(inputValue));
    } else {
      // Show alert message for non-numeric input
      alert("Please enter digits only for the year");
      // Clear the input field
      setYear("");
    }
  };

  const handleHeadlineChange = (e) => {
    setHeadline(e.target.value);
  };
  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const onBannerImagePicked = (event) => {
    const file = event.target.files[0];
    handleFileSelection(
      file,
      setBannerImageFile,
      setBannerImagePreview,
      setBannerFileSizeError
    );
  };

  const onMagazinePicked = (event) => {
    const file = event.target.files[0];
    handleFileSelection(file, setMagazineFile, null, setMagazineFileSizeError);
  };

  const handleFileSelection = (file, setFile, setPreview, setError) => {
    const maxSizeInKB = 50000; // Define your maximum size in KiloBytes (KB)

    if (file) {
      const fileSizeInKB = file.size / 1024;

      if (fileSizeInKB > maxSizeInKB) {
        alert("File size exceeds the maximum allowed size.");
        setError(true);
        setFile(null);
        if (setPreview) setPreview("");
      } else {
        setFile(file);
        if (setPreview) {
          const reader = new FileReader();
          reader.onload = () => {
            setPreview(reader.result);
          };
          reader.readAsDataURL(file);
        }
        setError(false);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = new FormData();
      data.append("year", year);
      data.append("issueDate", headline);
      data.append("month", month);
      if (bannerImageFile) {
        data.append("magzineImg", bannerImageFile);
      }
      console.log(bannerImageFile, "bannerImageFile");
      if (magazineFile) {
        data.append("magzinePdf", magazineFile);
      }
      console.log(data, "datadatadata");
      if (id.id) {
        await dispatch(postMagzineUpdate(id.id, data));
        navigate("/managemagzine");
      } else {
        await dispatch(postMagzineAdd(data));
        navigate("/managemagzine");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    const fetchMagzine = async () => {
      try {
        const resp = await dispatch(getSingleMagzine(id.id));
        console.log(resp, "poiuytrewq");
        if (resp) {
          setHeadline(resp?.data ? resp?.data.issueDate : "");
          setYear(resp?.data ? resp?.data.year : "");
          setBannerImageFile(resp?.data ? resp?.data.magzineImg : "");
          setBannerImagePreview(resp?.data ? resp?.data.magzineImg : "");
          setBannerImagePreview(
            resp?.data
              ? `https://api.agronayan.com/s3-images/${resp?.data.magzineImg}`
              : ""
          );
          setMagazineFile(resp?.data ? resp?.data.magzinePdf : "");
          setMonth(resp?.data ? resp?.data.month : "");
        }
      } catch (error) { }
    };

    fetchMagzine();
  }, [dispatch, id.id]);

  return (
    <div>
      <div className="video-section">
        <div className="line"></div>
        <div className="card">
          <div className="card-header">
            {id.id ? "Edit Magazine" : "Add Magazine"}
          </div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="exampleText1">Add Magazine Headline</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add Headline"
                  value={headline}
                  onChange={handleHeadlineChange}
                />
              </div>

              <div className="news-img-container">
                <div className="form-group">
                  <label htmlFor="bannerImage">
                    Upload Magazine Cover Image
                  </label>
                  <input
                    type="file"
                    id="bannerImage"
                    accept="image/*"
                    onChange={onBannerImagePicked}
                  />
                  {bannerFileSizeError && (
                    <div className="error-message">
                      File size exceeds the maximum allowed size.
                    </div>
                  )}
                  {bannerImagePreview && (
                    <div className="image-preview">
                      <img src={bannerImagePreview} alt="Magazine Cover" />
                    </div>
                  )}
                </div>
              </div>

              <div className="Magazine-pdf-container">
                <div className="form-group">
                  <label htmlFor="magazineFile">Upload Magazine</label>
                  <input
                    type="file"
                    id="magazineFile"
                    accept=".pdf"
                    onChange={onMagazinePicked}
                  />
                  {magazineFileSizeError && (
                    <div className="error-message">
                      File size exceeds the maximum allowed size.
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Select Month</label>
                <select
                  className="form-control"
                  id="exampleSelect1"
                  value={month}
                  onChange={handleMonthChange}
                >
                  <option value="">Select Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Add Year</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  value={year}
                  onChange={handleYearChange}
                  placeholder="Add Year"
                />
              </div>
              <button
                type="button"
                className="btn btn-danger btn-lg"
                onClick={handleSubmit}
              >
                {id.id ? "Update" : "Add"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

// import React, { useState, useEffect, useRef } from "react";
// import "./magzine.css";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams, useNavigate } from "react-router-dom";
// import {
//   getSingleVideo,
//   postVideoAdd,
//   postVideoUpdate,
// } from "../../../store/Action/videoAction";
// import { getSingleMagzine, postMagzineAdd, postMagzineUpdate } from "../../../store/Action/magzineAction";
// // import {useState} from

// export default function AddMagzine() {
//   const [year, setYear] = useState("");
//   const [headline, setHeadline] = useState("");
//   const [s3ImageId, setS3ImageId] = useState("");
//   const [selectedBannerImageFile, setSelectedBannerImageFile] = useState(null);
//   const [bannerImagePreview, setBannerImagePreview] = useState("");
//   const [bannerFileSizeError, setBannerFileSizeError] = useState(false);

//   const dispatch = useDispatch();
//   // const history = useHistory();
//   const id = useParams();
//   const navigate = useNavigate();

//   const handleYearChange = (e) => {
//     setYear(e.target.value);
//   };
//   const handleHeadlineChange = (e) => {
//     setHeadline(e.target.value);
//   };
//   const filePicker = useRef(null);
//   console.log(filePicker, "filePicker");

//   const onBannerImagePicked = (event) => {
//     const fileInput = filePicker.current; // Access the filePicker ref
//     const file = fileInput.files[0];
//     const maxSizeInKB = 20000; // Define your maximum size in KiloBytes (KB)

//     if (file) {
//       const fileSizeInKB = file.size / 1024;

//       if (fileSizeInKB > maxSizeInKB) {
//         // File size exceeds the allowed limit
//         alert("Logo image size exceeds the maximum allowed size.");
//         fileInput.value = ""; // Clear the input field
//         setBannerImagePreview("");
//         setBannerFileSizeError(true);
//       } else {
//         setSelectedBannerImageFile(file);
//         const reader = new FileReader();
//         reader.onload = () => {
//           setBannerImagePreview(reader.result);
//           setBannerFileSizeError(false);
//         };
//         reader.readAsDataURL(file);
//       }
//     }
//   };

//   const onBannerMagzinePicked = (event) => {
//     const fileInput = filePicker.current; // Access the filePicker ref
//     const file = fileInput.files[0];
//     const maxSizeInKB = 20000; // Define your maximum size in KiloBytes (KB)

//     if (file) {
//       const fileSizeInKB = file.size / 1024;

//       if (fileSizeInKB > maxSizeInKB) {
//         // File size exceeds the allowed limit
//         alert("Logo image size exceeds the maximum allowed size.");
//         fileInput.value = ""; // Clear the input field
//         setBannerImagePreview("");
//         setBannerFileSizeError(true);
//       } else {
//         setSelectedBannerImageFile(file);
//         const reader = new FileReader();
//         reader.onload = () => {
//           setBannerImagePreview(reader.result);
//           setBannerFileSizeError(false);
//         };
//         reader.readAsDataURL(file);
//       }
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const data = new FormData();
//       data.append("year", year);
//       data.append("issueDate", headline);
//       if (selectedBannerImageFile) {
//         data.append("magzineImg", selectedBannerImageFile);
//       }

//       if (id.id) {
//         await dispatch(postMagzineUpdate(id.id, data));
//         navigate("/managevideos");
//       } else {
//         await dispatch(postMagzineAdd(data));
//         navigate("/managevideos");
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };

//   useEffect(() => {
//     const fetchCategory = async () => {
//       try {
//         const resp = await dispatch(getSingleMagzine(id.id));
//         if (resp) {
//           console.log(resp, "resprespresp");
//           setHeadline(resp?.data ? resp?.data.title : "");
//           // setVideoUrl(resp?.data ? resp?.data.video_link : "");
//           setBannerFileSizeError(resp?.data ? resp?.data?.thumbnailImage : "");
//         }
//       } catch (error) {}
//     };

//     fetchCategory();
//   }, [dispatch, id.id]);

//   return (
//     <div>
//       <div className="video-section">
//         <div className="line"></div>
//         <div className="card">
//           <div className="card-header">
//             {id.id ? "Edit Magzine" : "Add Magzine"}
//           </div>
//           <div className="card-body">
//             <form>
//               <div className="form-group">
//                 <label htmlFor="exampleText1">Add Year </label>
//                 <input
//                   type="url"
//                   className="form-control"
//                   id="exampleText1"
//                   aria-describedby="emailHelp"
//                   value={year}
//                   onChange={handleYearChange}
//                   placeholder="Add Video URL "
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="exampleText1">Add Video HeadLine</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="exampleText1"
//                   aria-describedby="emailHelp"
//                   placeholder="Add HeadLine"
//                   value={headline}
//                   onChange={handleHeadlineChange}
//                 />
//               </div>

//               <div className="news-img-container">
//                 <div className="form-group ">
//                   <label htmlFor="exampleText1">Upload Magzine Image</label>
//                   <div className="add-img">
//                     <button
//                       type="button"
//                       onClick={() => filePicker.current.click()} // Access the filePicker ref
//                     >
//                       + Image
//                     </button>
//                     <input
//                       type="file"
//                       ref={filePicker}
//                       onChange={onBannerImagePicked}
//                     />
//                     {bannerFileSizeError && (
//                       <div className="error-message">
//                         File size exceeds the maximum allowed size.
//                       </div>
//                     )}
//                   </div>
//                   {bannerImagePreview || s3ImageId ? (
//                     <div className="image-preview">
//                       <img
//                         src={
//                           bannerImagePreview
//                             ? bannerImagePreview
//                             : `https://stage-api.iirfranking.com/s3-images/${s3ImageId}`
//                         }
//                         alt="test alt name"
//                       />
//                     </div>
//                   ) : null}
//                 </div>
//               </div>

//               {/* <div className="Magzine-pdf-container">
//                 <div className="form-group ">
//                   <label htmlFor="exampleText1">Upload Magzine</label>
//                   <div className="add-img">
//                     <button
//                       type="button"
//                       onClick={() => filePicker.current.click()} // Access the filePicker ref
//                     >
//                       + Image
//                     </button>
//                     <input
//                       type="file"
//                       ref={filePicker}
//                       onChange={onBannerMagzinePicked}
//                     />
//                     {bannerFileSizeError && (
//                       <div className="error-message">
//                         File size exceeds the maximum allowed size.
//                       </div>
//                     )}
//                   </div>
//                   {bannerImagePreview || s3ImageId ? (
//                     <div className="image-preview">
//                       <img
//                         src={
//                           bannerImagePreview
//                             ? bannerImagePreview
//                             : `https://stage-api.iirfranking.com/s3-images/${s3ImageId}`
//                         }
//                         alt="test alt name"
//                       />
//                     </div>
//                   ) : null}
//                 </div>
//               </div> */}

//               <button
//                 type="button"
//                 className="btn btn-danger btn-lg"
//                 onClick={handleSubmit}
//               >
//                 {id.id ? "Update" : "Add"}
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
