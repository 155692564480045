import {
    GET_BLOG_CATEGORY,
} from "../Action/actionType";
const initialState = "";

const blogCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BLOG_CATEGORY:
            return action.payload
        default: return state;
    }
}

export default blogCategoryReducer;